<template>
    <div class="table-responsive mb-4">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>SL</th>
                    <th v-if="false">Code/SKU</th>
                    <th>Product Name</th>
                    <th>Stock Balance</th>
                    <th class="qty">Qty</th>
                    <th class="qty">Rate</th>
                    <th v-if="false">Amount</th>
                    <th v-if="false">TD/Commission (%)</th>
                    <th v-if="false">TD/Commission Amount</th>
                    <th v-if="false">Offer Discount</th>
                    <th v-if="false">Amount After Discount</th>
                    <th v-if="false">VAT</th>
                    <th v-if="false">VAT Amount</th>
                    <th>Total Amount</th>
                    <th>Free QTY</th>
                    <th>Action</th>
                </tr>   
            </thead>
            <tbody v-if="itemDetails" ref="tableRef">
              <RowInline
                  v-for="(item, i) in itemDetails" :key="item.id"
                  :item="item" :index="i"
                  :vatRate="vatRate"
                  @onOpenEditItem="onOpenEditItem"
                  @onDeleteItem="onDeleteItem"
              />
            </tbody>
        </table>
    </div>
</template>

<script setup>
import useDetectOutsideClick from '@/services/useDetectOutsideClick';
import {computed, ref} from "vue";
import RowInline from "./ProductTableRow.vue";
import {vatRate} from '@/data/inventory.js'

    const props = defineProps({
        itemDetails: {
            type: Array,
            default: []
        },
        formRef: {
            type: Object,
        }
    })

    const tableRef = ref(null)

    useDetectOutsideClick(tableRef, () => {
      resetItemList();
    });

    const amount = computed(() => props.item.amount = (props.item.rate * props.item.quantity).toFixed(4))

    const onOpenEditItem = (index) => {
      resetItemList(index);
    }

    const resetItemList = (selectedIndex = -1) => {
      for(let i = 0; i < props.itemDetails.length; i++){

        if(selectedIndex === i){
          props.itemDetails[i].isEdit = true;
          continue;
        }
        props.itemDetails[i].isEdit = false;
      }
    }
    const onDeleteItem = (index) => {
      props.itemDetails.splice(index, 1);
    }
    
</script>

<style>
.create-billing > :not(first-child) > * {
  padding-left: 10px !important;
}
.create-billing > :not(caption) > * > * {
  padding: 0.72rem 0.2rem;
}
</style>
